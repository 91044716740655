import { AuthOptions, OAuthOpts } from '@aws-amplify/auth/lib/types';

const redirectUri = (location.hostname === 'localhost' || location.hostname === '127.0.0.1')
  ? 'http://localhost:4200/login/'
  : 'https://admin.dev.app.1bill.com/login/';

const oauth = {
  domain: '1bill-admin-dev.auth.ap-southeast-2.amazoncognito.com',
  // domain: 'auth.dev.app.1bill.com',
  scope : ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: redirectUri,
  redirectSignOut: redirectUri,
  responseType: 'code',
} as OAuthOpts;

const auth = {
  identityPoolId: 'ap-southeast-2:7a16b6a1-e5dd-4ad3-afd7-a593bb4c0600',
  region: 'ap-southeast-2',
  identityPoolRegion: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_AstdRIcKf',
  userPoolWebClientId: '5n6ej2utdee5rg5rm3a9joo2cq',
  oauth,
} as AuthOptions;


export const amplify = {
  Auth: auth,
};
