import { Injectable } from '@angular/core';
import Auth from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { Subject, Observable } from 'rxjs';
import { CognitoUser } from 'amazon-cognito-identity-js';

export interface NewUser {
  email: string;
  phone: string;
  password: string;
  firstName: string;
  lastName: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public loggedIn: boolean;
  private _authState: Subject<CognitoUser|any> = new Subject<CognitoUser|any>();
  authState: Observable<CognitoUser|any> = this._authState.asObservable();


  constructor( ) {
    Hub.listen('auth', (data) => {

      // console.log('auth data', data);
      const { channel, payload } = data;
      if (channel === 'auth') {
        this._authState.next(payload.event);
      }
    });
  }

  async signOut(): Promise<any> {
    await Auth.signOut();
    return this.loggedIn = false;
  }
  openHostedUI() {
    return Auth.federatedSignIn();
  }

  currentAuthenticatedUser(){
    return Auth.currentAuthenticatedUser();
  }
}
