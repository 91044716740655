import {Component, NgZone, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {Router, ActivatedRoute} from "@angular/router";
import {Auth, Hub} from "aws-amplify";
import { Subscription } from 'rxjs';

@Component({
  selector: 'ngx-one-bill-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  subsQueryParams: Subscription;
  error: any;
  constructor(
    private auth: AuthService,
    private _router: Router,
    private _zone: NgZone,
    private rotue: ActivatedRoute,
  ) {
    this.subscribeNavParams();
    Hub.listen('auth', ({ payload: { event, data } }) => {
      // console.log(event);
      // console.log(data);
      switch (event) {
        case 'signIn':
          this._zone.run(() => {
            this._router.navigate(['/']);
          });
          break;
        case 'signOut':
          this._zone.run(() => {
            this._router.navigate(['/login']);
          });
          break;
      }
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        this._router.navigate(['/']);
      })
      .catch(() => {});
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    this.subsQueryParams.unsubscribe();
  }
  onClick() {
    this.auth.openHostedUI();
  }
  subscribeNavParams() {
    this.subsQueryParams = this.rotue.queryParams.subscribe(param => {
      this.error = null;
      // tslint:disable-next-line: no-string-literal
      const error = param['error'], errorDescription: string = param['error_description'];
      if (error) {
        if (errorDescription.includes('PreSignUp failed with error')) {
          this.error = errorDescription.split('PreSignUp failed with error')[1];
        } else if (errorDescription.includes('PreAuthentication failed with error')) {
          this.error = errorDescription.split('PreAuthentication failed with error')[1];
        } else {
          this.error = errorDescription;
        }
      }
    });
  }

}
